<template>
  <transition name="modal">
    <div
      v-if="showModal"
      class="modal-mask fixed z-[9998] top-0 left-0 w-full h-full bg-[rgba(0,32,63,0.7)] table transition-opacity duration-300 ease-in-out"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
    >
      <div
        class="modal-wrapper absolute flex max-h-[90%] w-full max-w-[600px] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-[calc(50%+1px)]"
        :class="{
          '!max-w-max': maxContent,
          '!max-w-[500px]': md,
          '!max-w-[900px]': lg,
          '!max-w-[1250px]': xl
        }"
      >
        <LocalLoader isMedium :loading="loading" />
        <div
          class="modal-container relative w-fit-content w-full mx-auto overflow-y-auto p-4 bg-white rounded-lg shadow-md transition-all duration-300 ease mobileLandscape:p-8"
          :class="{ 'blur-[1.5px]': loading }"
          @click.stop
        >
          <div class="modal-header relative">
            <i
              v-if="close"
              class="fas fa-times absolute -right-3 -top-3 cursor-pointer text-neutral-200 hover:text-neutral-200"
              @click="(e) => emit('close', e)"
            />
            <slot name="header" />
          </div>
          <div class="modal-body my-4">
            <slot name="body" />
          </div>
          <div class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup>
import LocalLoader from '@/components/shared/loaders/LocalLoader'
import { ref } from 'vue'

const props = defineProps({
  showModal: Boolean,
  close: Boolean,
  loading: Boolean,
  maxContent: Boolean,
  md: Boolean,
  lg: Boolean,
  xl: Boolean
})
const emit = defineEmits(['close'])

const mouseDownInside = ref(false)

function handleMouseDown(e) {
  mouseDownInside.value = e.target.closest('.modal-container') !== null
}

function handleMouseUp(e) {
  const mouseUpInside = e.target.closest('.modal-container') !== null
  if (!mouseDownInside.value && !mouseUpInside) {
    emit('close', e)
  }
}
</script>
