import ApiEasycatService from '@/api/new/services/api.easycat.service'
import ApiLibraryService from '@/api/new/services/api.library.service'
import ApiService from '@/api/new/services/api.service'
import ApiServiceV2 from '@/api/new/services/v2/api.service'
import i18n from '@/i18n'

import { router } from '@/router/index'

const state = {
  task: {
    file: {
      status: null
    },
    revision: {
      status: null
    },
    process: {
      type: null,
      status: null
    }
  },
  currentSupplier: {},
  currentTask: {},
  matched: {
    segments: [],
    found: null
  },
  file: [],
  filePages: {
    current: 0,
    total: 0,
    totalSegments: 0,
    pageSize: 0
  },
  searchPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 50
  },
  revisionHistory: [],
  caasContent: [],
  currentSegmentName: '',
  currentQaScores: [],
  isReviewSaved: true,
  isSavingSegment: false
}

const mutations = {
  setTaskProcess(state, task) {
    if (task.review) {
      state.task.process.status = task.review.attributes.status
      state.task.process.type = task.review.attributes.type
      state.task.revision.status = task.review.attributes.revision_status
    } else {
      state.task.process.status = task.status
      state.task.process.type = task.type
      state.task.revision.status = task.revision_status
    }
  },
  setFilteredRevision(state, file) {
    state.revision.id = file.id
    state.revision.content = file.attributes.content
  },
  setCurrentSupplier(state, supplier) {
    state.currentSupplier = supplier
    state.task.file.status = supplier.status
  },
  setCurrentTask(state, task) {
    state.currentTask = task
  },
  setMatchedSegments(state, matched) {
    state.matched.segments = matched.segments
    state.matched.found = matched.found
  },
  setRevisionFile(state, file) {
    state.file = file
  },
  setRevisionFileSegment(state, { index, segment }) {
    state.file[index] = segment
  },
  setRevisionFileSegmentTarget(state, { index, target, confirmed }) {
    state.file[index].attributes.target = target
    state.file[index].attributes.is_confirmed = confirmed
  },
  setFilePages(state, pages) {
    state.filePages.current = pages.current_page
    state.filePages.total = pages.last_page
    state.filePages.totalSegments = pages.total
    state.filePages.pageSize = pages.per_page
  },
  setSearchPages(state, pages) {
    state.searchPages.current = pages.current_page
    state.searchPages.total = pages.last_page
    state.searchPages.totalSegments = pages.total
    state.searchPages.pageSize = pages.per_page
  },
  setCurrentPage(state, page) {
    state.filePages.current = page
  },
  setRevisionHistory(state, history) {
    state.revisionHistory = history
  },
  setCaasContent(state, content) {
    state.caasContent = content
  },
  setCurrentSegmentName(state, segmentName) {
    state.currentSegmentName = segmentName
  },
  setQaScores(state, qaScores) {
    state.currentQaScores = qaScores
  },
  setIsReviewSaved(state, bool) {
    state.isReviewSaved = bool
  },
  setSavingSegment(state, saving) {
    state.isSavingSegment = saving
  }
}

const actions = {
  async getTask(
    { commit, dispatch, state, getters, rootGetters },
    { projectId, taskId, perPage }
  ) {
    await ApiServiceV2.get(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/tasks/${taskId}`
    )
      .then(async (res) => {
        if (res.data.data.attributes.supplier) {
          const supplier = res.data.data.attributes.supplier.attributes
          commit('setCurrentSupplier', supplier)
        }
        const file = res.data.data.attributes
        const task = res.data.data

        await commit('setTaskProcess', file)
        await commit('setCurrentTask', task)
        if (getters.isTranslationTask) {
          await dispatch(
            'file/getTranslationFile',
            {
              source: file.source_language,
              target: file.target_language,
              currentPage: 1,
              perPage
            },
            {
              root: true
            }
          )
        } else {
          await dispatch('getFile', {
            id: file.string_library_id,
            source: file.source_language,
            targets: file.target_language,
            currentPage: 1,
            perPage: 100
          })
        }
        if (task.attributes.type === 'caas') {
          await dispatch('ContentPreview')
        }
      })
      .catch((err) => {
        const project = rootGetters['project/projectTasks'].find(
          (project) =>
            project.target === state.currentTask.attributes.target_language
        )
        const task = project.tasks.find(
          (task) => task.attributes.type === 'internal_review'
        )
        router.push({
          name: 'clientRevisionEditor',
          params: { id: projectId, taskId: task.id }
        })
        throw new Error(JSON.stringify(err))
      })
  },
  async getFile(
    { commit, dispatch, rootGetters, state },
    { id, source, targets, currentPage }
  ) {
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${id}/bilingual?filters[source_language][]=${source}&filters[target_language][]=${targets}`,
      { params: { page: currentPage, perPage: 100 } }
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        commit('setRevisionFile', file)
        commit('setFilePages', pages)

        if (state.currentTask.attributes.type !== 'caas') {
          await dispatch('getRevisionHistory')
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateSegments({ state, dispatch, commit, rootGetters }, contents) {
    const data = {
      data: {
        type: 'bilingual',
        attributes: {
          status: 'draft',
          strings: [
            {
              translation_id: contents.translation_id,
              text: contents.text
            }
          ]
        }
      }
    }

    await ApiLibraryService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.currentTask.attributes.string_library_id}/bilingual`,
      data
    )
      .then(async () => {
        if (contents.search) {
          await dispatch('filterTaskSegments', contents.search)
        } else {
          await dispatch('getFile', {
            id: state.currentTask.attributes.string_library_id,
            source: state.currentTask.attributes.source_language,
            targets: state.currentTask.attributes.target_language,
            currentPage: state.filePages.current
          })
        }

        if (state.currentTask.attributes.type != 'caas') {
          dispatch('getRevisionHistory')
        }
        await commit('setIsReviewSaved', true)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateContentMap({ rootGetters, rootState, commit }, content) {
    const data = {
      data: {
        type: 'translation-key',
        attributes: {
          name: rootState.project.currentProject.attributes.meta.key_name,
          caas_content: content
        }
      }
    }
    await ApiLibraryService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${rootState.project.currentProject.attributes.meta.source_library_id}/caas-content`,
      data
    )
      .then((res) => {
        let content = res.data.data.attributes.caas_content
        if (content === null) {
          content = []
        }
        commit('setCaasContent', content)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async ContentPreview({ rootGetters, rootState, commit }) {
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${rootState.project.currentProject.attributes.meta.source_library_id}/keys/${rootState.project.currentProject.attributes.meta.key_id}`
    )
      .then((res) => {
        let content = res.data.data.attributes.caas_content
        if (content === null) {
          content = []
        }
        commit('setCaasContent', content)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async confirmSegment({ state, rootGetters, dispatch }, { translationId }) {
    const data = {
      data: {
        type: 'translation-confirmed',
        attributes: {
          translation_ids: [translationId]
        }
      }
    }
    await ApiLibraryService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.currentTask.attributes.string_library_id}/translations/confirmed`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async unconfirmSegment({ state, rootGetters }, { translationId }) {
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.currentTask.attributes.string_library_id}/translations/${translationId}/unconfirmed`
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async confirmAllSegments({ dispatch, state, rootGetters }) {
    await ApiLibraryService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.currentTask.attributes.string_library_id}/bilingual/confirm-all`
    )
      .then(async () => {
        await dispatch('getFile', {
          id: state.currentTask.attributes.string_library_id,
          source: state.currentTask.attributes.source_language,
          targets: state.currentTask.attributes.target_language,
          currentPage: state.filePages.current
        })
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.segments_confirm') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async unconfirmAllSegments({ dispatch, state, rootGetters }) {
    await ApiLibraryService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.currentTask.attributes.string_library_id}/bilingual/unconfirm-all`
    )
      .then(async () => {
        await dispatch('getFile', {
          id: state.currentTask.attributes.string_library_id,
          source: state.currentTask.attributes.source_language,
          targets: state.currentTask.attributes.target_language,
          currentPage: state.filePages.current
        })
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.segments_unconfirm') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async finishInternalReview({ state, rootState, rootGetters }) {
    const projectId = rootState['project'].currentProject.id
    const taskId = state.currentTask.id
    const data = {
      data: {
        type: 'task_revision',
        attributes: {
          status: 'finished',
          changes: []
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/tasks/${taskId}/revisions/internal-review`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async finishRevision({ dispatch, rootGetters }, { projectId, taskId }) {
    const data = {
      data: {
        type: 'task_revision',
        attributes: {
          status: 'finished',
          changes: []
        }
      }
    }
    dispatch('loading/startLoader', {}, { root: true })
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/tasks/${taskId}/revisions`,
      data
    )
      .then(async () => {
        setTimeout(async () => {
          await router.push({
            name: 'clientProject',
            params: { id: projectId }
          })
        }, 800)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
      .finally(() => {
        dispatch('loading/stopLoader', {}, { root: true })
      })
  },
  async filterTaskSegments(
    { dispatch, commit, state, rootGetters },
    { id, text, lang }
  ) {
    if (text.length === 0)
      return await dispatch('getFile', {
        id: state.currentTask.attributes.string_library_id,
        source: state.currentTask.attributes.source_language,
        targets: state.currentTask.attributes.target_language,
        currentPage: state.filePages.current
      })
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${id}/search?query=${text}&&language_code=${lang}`
    )
      .then(async (res) => {
        const file = res.data.data
        commit('setRevisionFile', file)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchTm({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation_memories',
        attributes: {
          source_language: payload.source,
          target_language: payload.target,
          value: payload.value,
          type: payload.type
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/search?page=${payload.currentPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setMatchedSegments', obj)
        commit('setSearchPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchTb({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          source_language: payload.source,
          target_language: payload.target,
          value: payload.value,
          type: payload.type
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/search?page=${payload.currentPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setMatchedSegments', obj)
        commit('setSearchPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getRevisionHistory({ commit, state, rootGetters }) {
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.currentTask.attributes.string_library_id}/bilingual/history?language_code=${state.currentTask.attributes.target_language}&page=${state.filePages.current}&perPage=100`
    )
      .then(async (res) => {
        const history = res.data.data
        await commit('setRevisionHistory', history)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateQualityAssurance(
    { state, dispatch, rootGetters },
    { projectId, taskId, score }
  ) {
    const data = {
      data: {
        id: taskId,
        type: 'qa',
        attributes: {
          key_name: state.currentSegmentName,
          qa_score: {
            ...score
          }
        }
      }
    }

    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/tasks/${taskId}/qa`,
      data
    )
      .then(async () => {
        await dispatch('getQaScores', { projectId, taskId })
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.update_qa') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getQaScores({ commit, rootGetters }, { projectId, taskId }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/tasks/${taskId}/qa`
    )
      .then(async (res) => {
        commit('setQaScores', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  previewSegments: (state) => {
    if (state.caasContent.length == 0) return []
    const translationIds = state.caasContent.map((content) => {
      return content.translation_id
    })
    let segments = state.file
      .map((file) => {
        if (translationIds.includes(file.attributes.translations[1].id)) {
          const content = state.caasContent.find(
            (content) =>
              content.translation_id == file.attributes.translations[1].id
          )
          return {
            translation: file.attributes.translations[1],
            translation_id: content.translation_id,
            order: content.order
          }
        }
      })
      .filter((el) => el !== undefined)
      .sort((a, b) => a.order - b.order)
    return segments
  },
  isCaas: (state) => {
    return state.currentTask.attributes.type === 'caas'
  },
  isAi: (state, getters, rootState, rootGetters) => {
    const tasks = rootState['project'].currentProject.attributes.tasks
    return tasks.some((t) => t.attributes.type === 'ai_translation')
  },
  isAiTask: (state, getters, rootState, rootGetters) => {
    return state.currentTask.attributes.type === 'ai_translation'
  },
  isTranslationTask: (state, getters, rootState, rootGetters) => {
    return (
      state.currentTask.attributes.type !== 'qa' &&
      state.currentTask.attributes.type !== 'caas'
    )
  },
  getTaskBreadcrumb: (state) => {
    if (!state.currentTask.attributes) return ''
    return `${state.currentTask.attributes.source_language} > ${state.currentTask.attributes.target_language}`
  },
  getSubjectTitle: (state, getters, rootState, rootGetters) => (lang) => {
    if (!rootState.project.currentProject.attributes) return ''
    return `${rootGetters['account/langCodeFormat'](
      rootState.project.currentProject.attributes.source_language
    )} > ${rootGetters['account/langCodeFormat'](lang)}`
  },
  getQaScore: (state) => {
    if (!state.currentSegmentName || state.currentQaScores.length === 0) {
      return
    }
    const score = state.currentQaScores.find(
      (qa) => qa.attributes['key-name'] === state.currentSegmentName
    )
    return score && score.attributes.qa_score
  }
}

export const task = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
