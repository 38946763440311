import ApiEasycatService from '@/api/new/services/api.easycat.service'

const state = {
  sharedTms: [],
  tmLanguages: {
    sources: [],
    targets: []
  },
  translationMemories: [],
  tmFilter: {},
  tmPages: {
    current: 1,
    total: 1,
    totalSegments: 10,
    pageSize: 10,
    from: 1,
    to: 10
  },
  currentTm: null,
  currentTmLanguage: null,
  tmSegments: [],
  replaceSegments: [],
  segmentsFilter: {},
  tmSegmentsPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 10
  },
  tmSegment: null,
  segmentHistory: null,
  segmentUsage: [],

  // Select
  selectedTms: [],
  selectAllFiltered: false,
  selectedReplaceSegments: [],
  selectReplaceAll: false
}

const mutations = {
  setSharedTms(state, tms) {
    state.sharedTms = tms
  },
  setTmLanguages(state, lang) {
    state.tmLanguages.sources = lang.source_languages
    state.tmLanguages.targets = lang.target_languages
  },
  setTranslationMemories(state, tms) {
    state.translationMemories = tms
  },
  updateTmFilter(state, { value, type }) {
    if (type && state.tmFilter[type] !== value) {
      state.tmFilter = {
        ...state.tmFilter,
        [type]: value
      }
      state.tmPages.current = 1
    }
  },
  resetTmFilter(state) {
    state.tmFilter = {}
  },
  setTmPages(state, tmPages) {
    state.tmPages.current = tmPages.current_page
    state.tmPages.total = tmPages.last_page
    state.tmPages.totalSegments = tmPages.total
    state.tmPages.pageSize = tmPages.per_page
    state.tmPages.from = tmPages.from
    state.tmPages.to = tmPages.to
  },
  setCurrentTm(state, tm) {
    state.currentTm = tm
  },
  setCurrentTmLanguage(state, lang) {
    state.currentTmLanguage = lang
  },
  setTmSegmentsPages(state, tmPages) {
    state.tmSegmentsPages.current = tmPages.current_page
    state.tmSegmentsPages.total = tmPages.last_page
    state.tmSegmentsPages.totalSegments = tmPages.total
    state.tmSegmentsPages.pageSize = tmPages.per_page
  },
  setTmSegments(state, { segments, replace }) {
    if (replace) state.replaceSegments = segments
    else {
      state.tmSegments = segments
      state.replaceSegments = segments
    }
  },
  updateSegmentsFilter(state, filter) {
    state.segmentsFilter = filter
  },
  setSegment(state, segment) {
    state.tmSegment = segment
  },
  setSegmentHistory(state, history) {
    state.segmentHistory = history
  },
  setSegmentUsage(state, usage) {
    state.segmentUsage = usage
  },

  // Select
  selectTm(state, tm) {
    if (!state.selectedTms.includes(tm)) {
      state.selectedTms = [...state.selectedTms, tm]
    }
  },
  deselectTm(state, tm) {
    const index = state.selectedTms.indexOf(tm)
    if (index > -1) {
      let result = [...state.selectedTms]
      result.splice(index, 1)
      state.selectedTms = result
    }
    if (state.selectedTms.length === 0) {
      state.selectAllFiltered = false
    }
  },
  setSelectedTms(state, tms) {
    state.selectedTms = tms
  },
  setSelectAll(state, bool) {
    state.selectAllFiltered = bool
  },

  // Select replace
  selectReplaceSegment(state, tm) {
    if (!state.selectedReplaceSegments.includes(tm)) {
      state.selectedReplaceSegments = [...state.selectedReplaceSegments, tm]
    }
  },
  deselectReplaceSegment(state, tm) {
    const index = state.selectedReplaceSegments.indexOf(tm)
    if (index > -1) {
      let result = [...state.selectedReplaceSegments]
      result.splice(index, 1)
      state.selectedReplaceSegments = result
    }
    if (state.selectedReplaceSegments.length === 0) {
      state.selectReplaceAll = false
    }
  },
  setSelectedReplaceSegments(state, tms) {
    state.selectedReplaceSegments = tms
  },
  setSelectReplaceAll(state, bool) {
    state.selectReplaceAll = bool
  }
}

const actions = {
  // Translation Memories
  async getStateSharedTms({ state, dispatch }) {
    if (state.sharedTms.length === 0) await dispatch('getSharedTms')
  },
  async getSharedTms({ commit, rootGetters }) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/shared/all`
    )
      .then((res) => {
        commit('setSharedTms', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateTmLanguages({ state, dispatch }) {
    if (
      state.tmLanguages.sources.length === 0 ||
      state.tmLanguages.targets.length === 0
    )
      await dispatch('getTmLanguages')
  },
  async getTmLanguages({ commit, rootGetters }) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/available-languages/all`
    )
      .then((res) => {
        commit('setTmLanguages', res.data.data.attributes)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getTranslationMemories({ commit, state, rootGetters }, payload) {
    const params = new URLSearchParams()
    params.append('page', payload?.page || state.tmPages.current)
    if (Object.values(state.tmFilter).length > 0) {
      Object.keys(state.tmFilter).forEach((key) => {
        if (state.tmFilter[key]) {
          if (key === 'targetLanguages') {
            state.tmFilter[key].forEach((l) => {
              params.append('targetLanguages[]', l)
            })
          } else {
            params.append(key, state.tmFilter[key])
          }
        }
      })
    }
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories`,
      {
        params
      }
    )
      .then((res) => {
        commit('setTranslationMemories', res.data.data)
        commit('setTmPages', res.data.meta)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async importTm({ dispatch, rootGetters }, payload) {
    const data = new FormData()
    data.append('data[type]', 'translation_memory')
    data.append('data[attributes][name]', payload.name)

    if (payload.translation_memory_id) {
      data.append(
        'data[attributes][translation_memory_id]',
        payload.translation_memory_id
      )
    }

    const readFileAsBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64Content = reader.result.replace(/^data:.+;base64,/, '')
          resolve(base64Content)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    }

    for (let y = 0; y < payload.files.length; y++) {
      try {
        const base64Content = await readFileAsBase64(payload.files[y])
        data.append(`data[attributes][content][${y}]`, base64Content)
        data.append(
          `data[attributes][file_name][${y}]`,
          payload.files[y]['name']
        )
      } catch (err) {
        throw new Error(err)
      }
    }

    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/import-from-csv`,
      data
    )
      .then(async () => {
        await dispatch('getSharedTms')
        await dispatch('getTmLanguages')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createTm({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation_memories',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories`,
      data
    )
      .then(async () => {
        await dispatch('getTranslationMemories')
        await dispatch('getTmLanguages')
        await dispatch('getSharedTms')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateTm({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation_memories',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${payload.id}`,
      data
    )
      .then(async () => {
        await dispatch('getTranslationMemories')
        await dispatch('getTm', { id: payload.id, getSegments: false })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteTm({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation_memories',
        attributes: {
          ids: payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/destroy`,
      data
    )
      .then(async () => {
        await dispatch('getTranslationMemories')
        await dispatch('getTmLanguages')
        await dispatch('getSharedTms')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async exportTm({ rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation_memories',
        attributes: {
          ...payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/export`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async getTm({ commit, dispatch, rootGetters }, payload) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${payload.id}`
    )
      .then(async (res) => {
        const data = res.data.data
        commit('setCurrentTm', data)
        if (payload.getSegments) {
          commit('setCurrentTmLanguage', data.attributes.target_languages[0])
          await dispatch('getTmSegments', { page: 1 })
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getTmSegments({ state, commit, rootGetters }, payload) {
    const tmId = state.currentTm.id
    const params = new URLSearchParams()
    params.append('page', payload?.page || state.tmSegmentsPages.current)
    if (Object.values(state.segmentsFilter).length > 0) {
      Object.keys(state.segmentsFilter).forEach((key) => {
        if (state.segmentsFilter[key]) {
          params.append(key, state.segmentsFilter[key])
        }
      })
    }
    if (state?.segmentsFilter?.targetLanguage) {
      commit('setCurrentTmLanguage', state.segmentsFilter.targetLanguage)
    } else {
      params.append('targetLanguage', state.currentTmLanguage)
    }
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${tmId}/segments`,
      { params }
    )
      .then((res) => {
        commit('setTmSegments', {
          segments: res.data.data,
          replace: payload?.replace || false
        })
        commit('setTmSegmentsPages', res.data.meta)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },

  // Translation Memory Segment
  async getTmSegment({ dispatch, commit }, id) {
    if (state?.tmSegment?.id === id) return
    await commit('setSegment', { id })
    await dispatch('getSegmentUsage')
    await dispatch('getSegmentHistory')
  },
  async createTmSegment({ state, dispatch, rootGetters }, payload) {
    const tmId = state.currentTm.id
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          ...payload,
          source_type: 'human'
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${tmId}/segments`,
      data
    )
      .then(async () => {
        await dispatch('getTmSegments')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateTmSegment({ state, dispatch, rootGetters }, payload) {
    const tmId = state.currentTm.id
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          ...payload.value
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${tmId}/segments/${payload.segmentId}`,
      data
    )
      .then(async () => {
        await dispatch('getTmSegments')
        await dispatch('getSegmentHistory')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteTmSegment({ state, dispatch, rootGetters }) {
    const tmId = state.currentTm.id
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          ids: state.selectedTms
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${tmId}/segments/destroy`,
      data
    )
      .then(async () => {
        await dispatch('getTmSegments')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async copyTmSegment({ state, dispatch, rootGetters }, payload) {
    const tmId = state.currentTm.id
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          ids: state.selectedTms,
          target_translation_memory_id: payload.targetTm
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${tmId}/segments/copy`,
      data
    )
      .then(() => {
        dispatch(
          'toast/success',
          {
            message: 'customer.toast.success.segments_copied',
            hasDynamicValues: true,
            boldOptions: true,
            options: [{ name: 'tm', value: payload.targetTmName }]
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async replaceTmSegment({ state, dispatch, rootGetters }, payload) {
    const tmId = state.currentTm.id
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          segment_ids: [...state.selectedReplaceSegments],
          target_language: state.currentTmLanguage,
          ...payload
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${tmId}/segments/replace-all`,
      data
    )
      .then(async () => {
        await dispatch('getTmSegments')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async exportTmSegment({ state, rootGetters }, payload) {
    const tmId = state.currentTm.id
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          target_languages: [state.currentTmLanguage],
          ids: [...state.selectedTms],
          ...(payload ? { email: payload } : {})
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${tmId}/segments/export`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async getSegmentHistory({ commit, state, rootGetters }) {
    const tmId = state.currentTm.id
    const id = state.tmSegment.id
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${tmId}/segments/${id}/history`
    )
      .then((res) => {
        commit('setSegmentHistory', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getSegmentUsage({ commit, state, rootGetters }) {
    const tmId = state.currentTm.id
    const id = state.tmSegment.id
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${tmId}/segments/${id}/usage`
    )
      .then((res) => {
        commit('setSegmentUsage', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },

  // Select
  async selectAll({ state, commit }, payload) {
    commit('setSelectAll', payload.isSelected)
    if (payload.overview) {
      const tms = state.translationMemories.map((tm) => tm.id)
      if (payload.isSelected) commit('setSelectedTms', tms)
      else commit('setSelectedTms', [])
    } else {
      const tmSegments = state.tmSegments.map((s) => s.id)
      if (payload.isSelected) commit('setSelectedTms', tmSegments)
      else commit('setSelectedTms', [])
    }
  },
  async selectAllReplace({ state, commit }, payload) {
    commit('setSelectReplaceAll', payload.isSelected)
    const tmSegments = state.replaceSegments.map((s) => s.id)
    if (payload.isSelected) commit('setSelectedReplaceSegments', tmSegments)
    else commit('setSelectedReplaceSegments', [])
  }
}

export const translationMemories = {
  namespaced: true,
  state,
  actions,
  mutations
}
